import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthContext } from "../components/AuthProvider";

const LoginPage = () => {

    const auth = useAuthContext();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/protected";

  if (auth.isAuthenticated()) {
    return <Navigate to={from} replace />;
  }

  return <h3>This is the login page</h3>;
};

export default LoginPage;
