import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ACCESS_TOKEN_KEY, USER_INFO_KEY } from "../config";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const signIn = (userInfo, accessToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
    setUser(userInfo);
    navigate("/protected");
  };

  const signOut = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER_INFO_KEY);
    setUser(null);
    navigate("/");
  };

  const isAuthenticated = () => {
    return !!user && !!localStorage.getItem(ACCESS_TOKEN_KEY);
  };

  useEffect(() => {
    const userInfo = localStorage.getItem(USER_INFO_KEY);
    if (userInfo && userInfo.length) {
      setUser(JSON.parse(userInfo));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
